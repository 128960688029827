/* GENERAL HTML -----------------------------------------------------------------*/
* {
  box-sizing: border-box;
}
body {
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.15px;
  background: #f9f9f9;
  transition: color 300ms ease, background-color 300ms ease;
  font-family: "Helvetica Neue LT W05_55 Roman", Arial, sans-serif;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  min-width: 320px;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Helvetica Neue LT W01_75 Bold", Arial, sans-serif;
  line-height: 22px;
  margin: calc(2rem - 0.14285em) 0 1rem;
}
h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
p:first-child {
  margin-top: 0;
}

h2 {
  font-size: 18px;
  line-height: 22px;
}
h3 {
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 0.75em;
}

p {
  margin: 0 0 1em;
  line-height: 1.467em;
}

a,
a.active {
  color: #fff;
  /* text-decoration: underline; */
}
a:hover {
  color: #fff;
  text-decoration: none;
}

/* a.active { 
  color: #fff; 
  transition-property: border-bottom;
  transition-duration: .2s;
  border-bottom: 1px solid transparent;
}

a:hover { 
  color: #fff; 
  border-bottom: 1px solid;
} */

/*GENERAL HTML - media queries*/
@media screen and (max-width: 1200px) {
}
@media screen and (max-width: 600px) {
  body {
    font-size: 14px;
  }
}

/*GENERAL APP -----------------------------------------------------------------*/
.app {
  text-align: center;
  /*background-color: #F9F9F9;*/
  min-height: 100vh;
  font-family: "Helvetica Neue LT W05_55 Roman", Arial, sans-serif;
}

.app-title {
  font-size: 1.5em;
}

.loader-wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  align-content: center;
  text-align: center;
  width: 100%;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 0;
}
.loader {
  border: 10px solid #e8e8e8;
  border-radius: 50%;
  border-top: 10px solid #424242;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  margin: 0 auto;
  opacity: 0.5;
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*GENERAL APP - media queries*/
@media screen and (max-width: 1200px) {
}

/*TOPBAR -----------------------------------------------------------------*/
.app-header {
  /*background-color: #F9F9F9;*/
  height: 60px;
  color: black;
  z-index: 1;
  position: relative;
}

.app-logo {
  height: 32px;
  float: left;
  transition: fill 300ms ease;
}

.mainmenu {
  width: 100%;
  font-family: "Helvetica Neue LT W01_75 Bold", Arial, sans-serif;
  font-size: 16px;
  line-height: 19px;
  padding: 0 30px;
}
.mainmenu > .item {
  float: left;
}
.mainmenu .item {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.2);
  transition: color 300ms ease;
  z-index: 11;
  height: 60px;
  text-decoration: none;
}
.rightmenu {
  display: flex;
  float: right;
}
.rightmenu .item {
  padding-left: 1em;
}
.rightmenu a:hover,
.rightmenu a.active {
  background: none;
  color: rgba(0, 0, 0, 1);
  transition: color 300ms ease;
}
/*TOPBAR - media queries*/
@media screen and (max-width: 1200px) {
}
@media screen and (max-width: 600px) {
  .rightmenu {
    margin-right: -1em;
  }
  .rightmenu .item {
    padding-left: 0.5em;
  }
}

/*PROJECTS -----------------------------------------------------------------*/

.card--project {
  position: absolute;
  width: 100vw;
  height: 100vh;
  padding-top: 30px;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

/*controls*/
.showreel {
}
.showreel > .button {
  position: fixed;
  z-index: 10;
  font-size: 16px;
  color: #000;
  text-decoration: none;
  backface-visibility: hidden;
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.showreel > .button.prev {
  left: -40px;
  top: 50vh;
}
.showreel > .button.next {
  right: -45px;
  top: 50vh;
}

/*PROJECTS - media queries*/
@media screen and (max-width: 1200px) {
}
@media screen and (max-width: 600px) {
  .card--project {
    max-height: calc(100vh - 45px);
  }
}

/*SLIDES -----------------------------------------------------------------*/

/*controls*/
.slideshow {
  /* overflow: hidden; */
}

.slideshow:focus {
  outline: none;
}

.slideshow > .button {
  position: fixed;
  z-index: 10;
  font-size: 16px;
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.slideshow > .button.prev {
  top: 5px;
  left: calc(50vw - 8px);
  transition: top 300ms ease;
}
.slideshow > .button.next {
  bottom: 5px;
  left: calc(50vw - 8px);
}

.card--slide .swipe {
  max-width: calc(100vw - 60px);
  width: 100vw;
  max-height: calc(100vh - 95px);
  height: 100vh;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: center;
  align-content: center;
  cursor: grab;
}

.card--slide .swipe:active { 
  cursor: grabbing;
}

.slide--text {
  background-color: #fff;
  max-width: 495px;
  min-width: 300px;
  max-height: calc(100vh - 100px);
  border-radius: 6px;
  overflow: auto;
  text-align: left;
  padding: 25px;
  margin: 0 auto;
}
.slide--text__title {
  margin-bottom: 0;
  font-size: 18px;
}
.slide--text__subtitle {
  margin-top: 0;
  font-family: "Helvetica Neue LT W05_55 Roman", Arial, sans-serif;
  font-weight: 400;
  font-size: 18px;
}

.slide--text a {
  color: #000;
  border-bottom: 1px solid #a5a5a5;
  text-decoration: none;
}

.slide--text a:hover {
  color: #000;
  border-bottom: 1px solid #000;
  text-decoration: none;
}

.slide--data {
  background-color: #fff;
  max-width: 495px;
  min-width: 300px;
  max-height: calc(100vh - 100px);
  border-radius: 6px;
  overflow: auto;
  text-align: left;
  padding: 25px;
  margin: 0 auto;
}
.slide--data__title {
  margin-bottom: 0;
  font-size: 18px;
}
.slide--data__subtitle {
  margin-top: 0;
  font-family: "Helvetica Neue LT W05_55 Roman", Arial, sans-serif;
  font-weight: 400;
  font-size: 18px;
}

.slide--data__row {
  position: relative;
  display: flex;
}

.slide--data__row .slide--data__row-label {
  font-family: "Helvetica Neue LT W01_75 Bold", Arial, sans-serif;
  font-size: 15px;	letter-spacing: -0.15px;	line-height: 20px;
  display: block;
  flex-grow: 1;
  min-width: 40%;
}

.slide--data__row .slide--data__row-label,
.slide--data__row .slide--data__row-input {
  margin: 1em 0;
}

@media screen and (max-width: 480px) {
  .slide--data__row {
    display: block;
  }
  .slide--data__row .slide--data__row-label,
  .slide--data__row .slide--data__row-input {
    min-width: 100%;
    margin: 0;
  }

  .slide--data__row .slide--data__row-label {
    margin-top: 1em;
  }

  .slide--data__row .slide--data__row-input {
    margin-bottom: 1em;
  }
}

.slide--data__row .slide--data__row-input {
  font-size: 15px;	letter-spacing: -0.15px;	line-height: 20px;
  display: block;
  flex-grow: 1;
  min-width: 60%;
}

/* .slide--text__button {
  border: 1px solid #979797;
  border-radius: 6px;
  background-color: #f8f8f8;
  width: 100%;
  height: 53px;
  color: #000000;
  font-family: "Helvetica Neue";
  font-size: 15px;
  letter-spacing: -0.15px;
  line-height: 22px;
  text-align: center;
  cursor: pointer;
}

.slide--text__button:hover {
  border: 1px solid #979797;
  background-color: #f4f4f4;
} */

.slide--image {
}
.slide--image img {
  max-width: calc(100vw - 60px);
  max-height: calc(100vh - 100px);
  border-radius: 6px;
}

.pointer--pointer {
  pointer-events: none;
}

.slide--video {
  border-radius: 6px;
  overflow: hidden;
  /* max-width: calc(100vw - 60px);
  width: 100vw;
  max-height: calc(100vh - 95px);
  height: 100vh; */

  position: relative;
  width: calc(100vw - 60px);
  height: 0;
  padding-bottom: 56.25%;
}

.slide--video iframe {
  /* height: calc(100vh - 95px); */
  /* height: auto;
  width: 100%; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/*SLIDES - media queries*/
@media screen and (max-width: 1200px) {
}
@media screen and (max-width: 600px) {
  .slideshow > .button.prev {
    top: 19px;
    transition: top 300ms ease;
  }
  .slide--video,
  .slide--image img {
    max-height: calc(100vh - 160px);
  }
  .slide--tekst {
    max-height: calc(100vh - 270px);
  }
}
@media screen and (max-width: 320px) {
  .slideshow > .button.prev {
    left: calc(50vw - 20px);
  }
  .slideshow > .button.next {
    left: calc(50vw - 20px);
  }
}

/* Ipad menubar 100vh fix */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .slide--image img {
    max-height: calc(90vh - 100px);
    margin-bottom: 30px;
  }

  .slide--text {
    max-height: calc(90vh - 100px);
    margin-bottom: 30px;
  }

  .slide--video {
    max-height: calc(90vh - 100px);
    margin-bottom: 30px;
  }
}
/*OVER ONS -----------------------------------------------------------------*/
.container {
  max-width: 1440px;
  margin: 20px auto;
  text-align: left;
  overflow: hidden;
}
.container .grid {
  margin: 0 30px;
}
.container .column:nth-child(n) {
  width: calc((99.99% / 3) - 65px);
  margin-right: 20px;
  padding: 20px 30px 40px 20px;
  float: left;
  border-radius: 6px;
  box-sizing: content-box;
  transition: color 300ms ease, background 100ms ease, opacity 100ms ease;
  opacity: 0;
}
.container .column:nth-child(3n) {
  margin-right: 0;
}
.column:nth-child(2) h3 {
  margin-bottom: 0.2em;
}
.column img {
  width: calc(100% + 50px);
  height: auto;
  margin: 0 -30px -10px -20px;
}
.column > h2:first-of-type,
.projectenoverzicht > h2:first-of-type,
.body > h2:first-of-type,
.contact > h2:first-of-type {
  margin-bottom: 4em;
  min-height: 1em;
}

.column:nth-child(3) svg {
  width: 1.5em;
  height: 1.5em;
}
.column:nth-child(3) h3 a {
  text-decoration: none;
}
.column:nth-child(3) h3 a:hover {
  /* text-decoration: underline; */
}

.column h3 a {
  text-decoration: none;
  vertical-align: middle;
}

.column h3 a span {
  transition-property: border-bottom;
  transition-duration: 0.2s;
  border-bottom: 1px solid transparent;
}

.column h3 a span:hover {
  border-bottom: 1px solid;
}

.no-overflow {
  overflow: visible;
}

.autocomplete_form {
  margin-top: 4em;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  color: #232323;
}

.autocomplete_form h2 {
  margin-bottom: 1em;
}

.autocomplete_form input {
  width: 100%;
  padding: 10px;
  border-radius: 3px;
  background-color: #eeeeee;
  border: #c5c5c5 1px solid;
  transition: 300ms border;
  color: #232323;
  font-size: 12px;
}

.autocomplete_form input:focus {
  border: #232323 1px solid;
  transition: 300ms border;
  outline: none;
}

.autocomplete_form .suggestions {
  background-color: #eeeeee;
  border: #c5c5c5 1px solid;
  border-radius: 3px;
  padding: 10px 0;
  position: absolute;
  width: 100%;
  max-height: 600px;
  overflow: scroll;
}

.autocomplete_form .suggestions a {
  display: block;
  padding: 10px;
  text-decoration: none;
  transition: 200ms background-color;
  transition: 200ms padding;
  color: #232323;
}

.autocomplete_form .suggestions a:hover {
  background-color: white;
  transition: 200ms background-color;
  transition: 200ms padding;
  padding-left: 15px;
}

.autocomplete_form .suggestions .suggestion-active {
  background-color: white;
  transition: 200ms background-color;
  transition: 200ms padding;
  padding-left: 15px;
}

.autocomplete_form .no-suggestions {
  padding: 10px 0;
  position: absolute;
  max-width: 300px;
  color: #232323;
}

.contact p {
  margin-top: 0;
}

.follow {
  margin: 2em 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.follow a,
.follow span {
  flex-grow: 1;
}

.follow a svg,
.column svg {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
}

.twitter--embed {
  width: calc(100% + 50px) !important;
  margin: 0 -30px 0 -20px;
  max-width: calc(100% + 50px) !important;
  position: relative;
}
.twitter--embed:after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  pointer-events: none;
  content: "";
  background: -moz-linear-gradient(
    top,
    rgba(35, 35, 35, 0) 0%,
    rgba(35, 35, 35, 0.5) 41%,
    rgba(35, 35, 35, 0.65) 70%,
    rgba(35, 35, 35, 0.89) 99%,
    rgba(35, 35, 35, 0.9) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(35, 35, 35, 0) 0%,
    rgba(35, 35, 35, 0.5) 41%,
    rgba(35, 35, 35, 0.65) 70%,
    rgba(35, 35, 35, 0.89) 99%,
    rgba(35, 35, 35, 0.9) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(35, 35, 35, 0) 0%,
    rgba(35, 35, 35, 0.5) 41%,
    rgba(35, 35, 35, 0.65) 70%,
    rgba(35, 35, 35, 0.89) 99%,
    rgba(35, 35, 35, 0.9) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00232323', endColorstr='#e6232323',GradientType=0 );
}

.projectenlijst {
  margin: 40px 0 0;
}
.project {
  margin: 0 0 20px;
}
.project h2 {
  margin-bottom: 0;
}
.project h3 {
  margin: 0;
}

.project h3 {
  font-family: "Helvetica Neue LT W05_55 Roman", Arial, sans-serif;
  font-weight: 500;
}
/*OVER ONS - media queries*/
@media screen and (max-width: 1200px) {
  .container .grid {
    display: flex;
    flex-flow: wrap;
  }
  .container .column:nth-child(n) {
    width: calc((99.99% / 2) - 60px);
    margin-bottom: 20px;
  }
  .container .column:nth-child(2n) {
    margin-right: 0;
  }
}
@media screen and (max-width: 620px) {
  .container .column:nth-child(n) {
    width: 100%;
    margin-right: 0;
  }
}

/*DARK THEME -----------------------------------------------------------------*/
.dark {
  background: #000;
  transition: color 300ms ease, background-color 200ms ease;
}
/*.dark .app { background: #000; }*/
/*.dark .app-header { background: #000; }*/

.dark .app-logo {
  fill: #fff;
  transition: fill 300ms ease;
}
.dark .rightmenu a {
  color: rgba(255, 255, 255, 0.5);
}
.dark .rightmenu a:hover,
.dark .rightmenu a.active {
  color: rgba(255, 255, 255, 1);
}

.dark .container .column:nth-child(n) {
  background: #232323;
  color: #f9f9f9;
  box-shadow: 0 6px 23px 0 rgba(0, 0, 0, 0.14);
  transition: color 300ms ease, background 100ms ease, opacity 700ms ease;
  opacity: 1;
}


/* DARK SEARCH THEME */

.light_search {
  transition: color 300ms ease, background-color 200ms ease;
}

.light_search .container .column_1 {
  background: white;
  color: #232323;
  border-radius: 6px;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px 30px 40px 20px;
  transition: color 300ms ease, background 100ms ease, opacity 700ms ease;
  opacity: 1;
}


.dark .container .remove_bg:nth-child(n) {
  background-color: #000;
  padding: 0;
}

.dark .container .remove_bg:nth-child(n) .block {
  background-color: #232323;
  padding: 20px 30px 40px 20px;
  border-radius: 6px;
  margin-bottom: 20px;
  width: 100%;
}